import styled from 'styled-components'

export const FooterContainer = styled.div`
    bottom: 0px; 
    background: rgb(27,27,27, 1);
    width: 100%;
    padding-bottom :0px;
    padding-left :0px;
    padding-right :0px;
    padding-top :7em;
    font-family: "Roboto", Arial, sans-serif;
    line-height: 1.8;
    font-weight:400;
    color: #999999

     ul,li,p,  h1, a {
        font-family: "Roboto", Arial, sans-serif;
        line-height: 1.8;
        font-weight:400;
        color: #999999; 
    }
`
